import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrowGo from "../../../assets/icons/arrow-go.svg";
import { LanguageProvider } from "../../../core/language-provider";
import cn from "../../../utils/classNames";
import InputBlock from "../InputBlock/Input";
import { PagePath } from "../../../utils/routes";
import { useForm } from "react-hook-form";
import { baseValidation } from "../../../utils/validation";
import { FaLinkedinIn, FaTwitter, FaFacebookF } from "react-icons/fa";
import LogoWhiteSvg from "../../../assets/icons/logowhite";

export default function Footer(props) {
    const isShort = props.short;

    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();

    const subscribeToNewsletter = async (data) => {
        navigate(PagePath.RegisterCongratulations);
    };

    return (
        <footer
            className={cn(
                "w-full bg-black px-4 text-white sm:px-8",
                isShort ? "py-8" : "pb-32 pt-16",
            )}
        >
            <div className="container mx-auto">
                {!isShort && (
                    <div className="flex flex-wrap items-stretch gap-16 lg:flex-row lg:items-start">
                        <div className="flex flex-col">
                            <h4 className="text--h4 uppercase">
                                {LanguageProvider.get("footer.contacts_title")}
                            </h4>
                            <p className="text--smallP sm:text--paragraph mt-4 ">
                                {LanguageProvider.get("footer.contacts_name")}
                            </p>
                            <p className="text--smallP sm:text--paragraph ">
                                {LanguageProvider.get("footer.contacts_place")}
                            </p>
                            <p className="text--smallP sm:text--paragraph mt-auto ">
                                {LanguageProvider.get("footer.contacts_email")}
                            </p>
                        </div>
                        <div className="mx-auto w-max sm:w-full sm:max-w-96">
                            <h4 className="text--h4 uppercase">
                                {LanguageProvider.get("footer.menu_title")}
                            </h4>
                            <div className="text--smallP sm:text--paragraph mx-auto mt-4 grid gap-4 md:grid-cols-2 lg:mx-0">
                                <div>
                                    <Link to={PagePath.Home}>
                                        {LanguageProvider.get("footer.menu_home")}
                                    </Link>
                                </div>
                                <div>
                                    <Link to={PagePath.ExploreBars}>
                                        {LanguageProvider.get("footer.menu_bar")}
                                    </Link>
                                </div>
                                <div>
                                    <Link to={PagePath.ExploreProducts}>
                                        {LanguageProvider.get("footer.menu_products")}
                                    </Link>
                                </div>
                                <div>
                                    <Link to={`${PagePath.ExploreBars}?map=true`}>
                                        {LanguageProvider.get("footer.menu_explore")}
                                    </Link>
                                </div>
                                <div>
                                    <Link to={'/faq'}>
                                        Faq
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="w-full basis-full uppercase lg:max-w-sm ">
                            <h4 className="text--h4 ">
                                {LanguageProvider.get("footer.news_title")}
                            </h4>
                            <div className="mt-4">
                                <form
                                    className="flex flex-1"
                                    onSubmit={handleSubmit(subscribeToNewsletter)}
                                >
                                    <InputBlock
                                        type="email"
                                        className="h-full rounded-r-none text-black"
                                        placeholder={LanguageProvider.get(
                                            "footer.news_placeholder",
                                        )}
                                        register={register("email", baseValidation)}
                                    />
                                    <button className="btn--primary rounded-l-none rounded-r-md py-4">
                                        <img src={arrowGo} />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                {!isShort && <div className="my-14 w-full border-b border-gray" />}
                <div className="flex w-full flex-col items-center justify-between gap-8 md:flex-row">
                    <div>
                        {isShort ? (
                            <p className="flex flex-col items-center gap-8 sm:flex-row">
                                <span className="text--h3 uppercase">
                                    {LanguageProvider.get("footer.contacts_title")}
                                </span>
                                <span>+39 333 34930282</span>
                                <span>info@dbars.com</span>
                            </p>
                        ) : (
                            <div className="flex items-center gap-4">
                                <a href="/">
                                    <FaLinkedinIn className="h-10 w-10 rounded-full border border-gray p-2.5" />
                                </a>
                                <a href="/">
                                    <FaFacebookF className="h-10 w-10 rounded-full border border-gray p-2.5" />
                                </a>
                                <a href="/">
                                    <FaTwitter className="h-10 w-10 rounded-full border border-gray p-2.5" />
                                </a>
                            </div>
                        )}
                    </div>
                    <LogoWhiteSvg />
                    <Link to={"/faq"}>
                        <span className="text--h3 uppercase">
                            FAQ
                        </span>
                    </Link>
                    <p>&copy;2024 {LanguageProvider.get("footer.copy")}</p>
                </div>
            </div>
        </footer>
    );
}
